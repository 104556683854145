import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import LandingPage from "../components/LandingPage/LandingPage";
import ContactForm from "../components/ContactForm/ContactForm";
import JuegosADCierzo from "../components/JuegosADCierzo/JuegosADCierzo";

export default function HomePage({ data }) {
  return (
    <main>
      <Backgrounds imageUrl={"/assets/home__bg.jfif"}></Backgrounds>
      <Banner data={data.banner} />
      <Header />
      <LandingPage data={data} />
      <JuegosADCierzo juegosADCierzo={data.juegosADCierzo} />
      <ContactForm />
      <Footer footer={data.footer} />
    </main>
  );
}

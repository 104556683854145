import styles from "./aboutUs.module.css";
import Card from "../Card/Card";
import PointAndText from "../PointAndText/PointAndText";
import IconLabel from "../IconLabel/IconLabel";

export default function AboutUs({ data }) {
  const { home, card } = data;

  return (
    <>
      <div className={styles.home}>
        {/* <Backgrounds imageUrl={aboutUsPage.backgroundImageUrl}></Backgrounds> */}
        <div className={styles.home__text}>
          <h1>{home.title}</h1>
          <p>{home.description}</p>
        </div>
      </div>
      <div>
        <Card>
          <div>
            <div className={styles.card__title}>
              <PointAndText
                text="Quienes somos"
                pointColor="var(--color-secondary)"
              />
              <h1>{card.title}</h1>
              <p>{card.description}</p>
            </div>
            <div className={styles.card__image}>
              <img src={card.imageSrc} alt="About Us" />
            </div>
            <div className={styles.card__text}>
              {card.sections.map((section, index) => (
                <div key={index}>
                  <p>{section}</p>
                </div>
              ))}
            </div>
            <div className={styles.grid}>
              {card.grid.map((item, index) => (
                <div className={styles.grid__item} key={index}>
                  <div>
                    <h1>{item.number}</h1>
                  </div>
                  <div>
                    <h2>{item.title}</h2>
                  </div>
                  <div>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.footer}>
              <h1>{card.footer.title}</h1>
              <p>{card.footer.description}</p>
            </div>
            <div className={styles.button}>
              <IconLabel
                text={card.button.text}
                backgroundColor="white"
                buttonBackgroundColor={card.button.buttonBackgroundColor}
                textColor={card.button.textColor}
                icon={
                  <img src="assets/icons/arrow__down__orange.svg" alt="arrow" />
                }
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

import "./App.css";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AbousUsPage";
import JuegosDelCierzoPage from "./pages/JuegosDelCierzoPage";
import FutbolPage from "./pages/FutbolPage";
import VolleyPage from "./pages/VolleyPage";
import BaloncestoPage from "./pages/BaloncestoPage";
import AvisoLegalPage from './pages/AvisoLegalPage'
import TransparenciaPage from './pages/TransparenciaPage'
import PoliticaPrivacidadPage from './pages/PoliticaPrivacidadPage'
import PrensaPage from './pages/PrensaPage'
import RaquetaPage from './pages/RaquetaPage'
import TiempoLibrePage from './pages/TiempoLibrePage'
import Media from './pages/Media'

export default function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  if (!data || !data.Voley) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage data={data} />} />
      <Route path="/sobre-nosotros" element={<AboutUsPage data={data} />} />
      <Route path="/juegos-del-cierzo" element={<JuegosDelCierzoPage data={data} />} />
      <Route path="/futbol-sala" element={<FutbolPage data={data} />} />
      <Route path="/baloncesto" element={<BaloncestoPage data={data} />} />
      <Route path="/voley" element={<VolleyPage data={data} />} />
      <Route path="/transparencia" element={<TransparenciaPage data={data} />} />
      <Route path="/politica-de-privacidad" element={<PoliticaPrivacidadPage data={data} />} />
      <Route path="/aviso-legal" element={<AvisoLegalPage data={data} />} />
      <Route path="/prensa" element={<PrensaPage data={data} />} />
      <Route path="/deportes-de-raqueta" element={<RaquetaPage data={data} />} />
      <Route path="/tiempo-libre" element={<TiempoLibrePage data={data} />} />
      <Route path="/galeria" element={<Media data={data} />} />
    </Routes>
  );
}

import { useNavigate } from "react-router-dom";
import styles from "./footer.module.css";

export default function Footer({ footer }) {
  const navigate = useNavigate();

  const year = new Date().getFullYear();
  function onClickTransparencia() {
    navigate("/transparencia");
    window.scrollTo(0, 0);
  }
  function onClickPrivacidad() {
    navigate("/politica-de-privacidad");
    window.scrollTo(0, 0);
  }
  function onClickAvisoLegal() {
    navigate("/aviso-legal");
    window.scrollTo(0, 0);
  }
  function onClickPrensa() {
    navigate("/prensa");
    window.scrollTo(0, 0);
  }

  function onClickCierzoImagen() {
    navigate("/");
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content__image}>
            <img
              onClick={onClickCierzoImagen}
              src={footer.logoSrc}
              alt="Logo Cierzo"
            />
          </div>
          <div className={styles.content__text}>
            <div className={styles.content__row1}>
              <div className={styles.row1__column1}>
                <h2>SOBRE A.D. CIERZO</h2>
                <br />
                <br />
                <div>
                  <a>Contacto</a>
                  <br />
                  <br />
                  <br />
                </div>
                <div onClick={onClickPrensa}>
                  <a>Prensa</a>
                  <br />
                  <br />
                  <br />
                </div>
                <div onClick={onClickTransparencia}>
                  <a>Transparencia</a>
                  <br />
                  <br />
                  <br />
                </div>

                <br />
                <br />
                <h2>COLABORAN</h2>
              </div>
              <div className={styles.row1__column2}>
                <h2>SÍGUENOS EN</h2>
                <br />
                <br />

                <div>
                  <a style={{ display: "flex" }}>
                    <img src="assets/Instagram.png" alt="Instagram" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Instagram
                  </a>
                  <br />
                  <br />
                  <br />
                </div>

                <div>
                  <a style={{ display: "flex" }}>
                    <img src="assets/Facebook.png" alt="Facebook" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Facebook
                  </a>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className={styles.content__row2}>
              {footer.sponsors.map((sponsor, index) => (
                <img key={index} src={sponsor} alt={`Sponsor ${index}`} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footer__cierzo}>
            <p>{year} A.D. Cierzo LGTB+</p>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.footer__links}>
            <a onClick={onClickPrivacidad}>Política de privacidad</a>
            <a onClick={onClickAvisoLegal}>Aviso legal</a>
            <a href="https://visco.uno/">Web hecha por VISCO</a>
          </div>
        </div>
      </div>
    </>
  );
}

import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";
import JuegosADCierzo from "../components/JuegosADCierzo/JuegosADCierzo";
import SportsPage from "../components/SportsPage/SportsPage";
import Logo from "../components/Logo/Logo";

export default function RaquetaPage({ data }) {
  return (
    <main>
      <Backgrounds imageUrl={"/assets/RaquetaBG.jpg"}></Backgrounds>
      <Logo />
      <Banner data={data.banner} />
      <Header />
      <SportsPage
        sportsPage={data.findYourTeam}
        sports={data.raqueta.sports}
      ></SportsPage>
      <JuegosADCierzo juegosADCierzo={data.juegosADCierzo} />
      <ContactForm />
      <Footer footer={data.footer} />
    </main>
  );
}

import styles from "./sportinfopopup.module.css";
import Card from "../Card/Card";
import IconLabel from "../IconLabel/IconLabel";
import { useState, useEffect } from "react";
import AccordionList from "../Accordion/AccordionList";
import CabraLogo from "../CabraLogo/CabraLogo";

/*
Props for the SportInfoPopUp component:

sportPopUp (object):
  - title: string, the title displayed in the popup
  - text: string, the main text displayed in the popup (supports line breaks)
  - mail: string, the email address displayed in the IconLabel component
  - popUpImage : string, image displayed on the component
  - costeActividad : array, activies fees
  - costeFederado : array, fees for federados
  - mapProps (object):
      -mapUrl (string): Google Maps link to the sport location
      -mapHeight (string): height of the map
      -mapWidth (string): width of the map
  - sportInfoArray: array of arrays, each subarray represents a team and contains:
      - cabraColor : string, color of the cabra
      - source: string, URL of the team image
      - teamName: string, name of the team
      - teamLocation: string, location where the team trains (displayed in the table)
      - teamHours: string, training hours (displayed in the table)
      - teamWeek: array of booleans, availability on Monday to Friday

      Available colors for the cabraColor; enter the '#...'

      const colors = [
      { name: 'Sky Blue', value: '#a7c5ff' },
      { name: 'Peach', value: '#ff8b74' },
      { name: 'Lavender', value: '#dcb8ff' },
      { name: 'Pink', value: '#f6aee4' },
      { name: 'Sunshine', value: '#fddb77' },
      { name: 'Cherry', value: '#ff5c72' },
      { name: 'Teal', value: '#57d4c1' },
      { name: 'Aqua', value: '#64d0eb' }
      ];
*/

export default function SportInfoPopUp({ sportPopUp }) {
  const PopUp = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className={styles.overlay}>
        <div className={styles.card}>
          <Card>
            <div className={styles.card__container}>
              <div className={styles.card__header}>
                <h1>{sportPopUp.title}</h1>
                <div className={styles.overlay__closer}>
                  <img src="assets/icons/cross.svg" onClick={onClose} />
                </div>
              </div>
              <div className={styles.card__content}>
                <div className={styles.card__column1}>
                  <p>
                    {sportPopUp.text.split("\n").map((line, index) => (
                      <span key={index}>
                        {line.trim()}
                        <br />
                      </span>
                    ))}
                  </p>{" "}
                  {/* Texto pasado como prop */}
                  <div className={styles.contact}>
                    <IconLabel
                      text={sportPopUp.mail} // Correo pasado como prop
                      onClick={() => alert("Button clicked")}
                      buttonBackgroundColor="#DCE3FF"
                      backgroundColor="white"
                      textColor="var(--primary-accent)"
                      icon={
                        <img
                          src={`/assets/icons/lillac__mail.svg`}
                          alt={`email`}
                        />
                      }
                    />
                  </div>
                  <div className={styles.teams}>
                    <h2>EQUIPOS</h2>
                    <div className={styles.teams__images__grid}>
                      {sportPopUp.sportInfoArray.map((team, index) => (
                        <div key={index} className={styles.team__image}>
                          <CabraLogo
                            cabraColor={team.cabraColor}
                            alt={team.teamName}
                          />
                          <p className={styles.team__name}>{team.teamName}</p>{" "}
                          {/* Nombre del equipo */}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.fees__container}>
                    <h2>CUOTA</h2>
                    <div className={styles.fees}>
                      <div className={styles.fees__activities}>
                        <h3>Coste de la actividad:</h3>
                        <ul>
                          {sportPopUp.costeActividad?.map((coste, index) => (
                            <li key={index}>{coste}</li>
                          ))}{" "}
                          {/* Generación automática de elementos li */}
                        </ul>
                      </div>
                      <div className={styles.fees__federados}>
                        <h3>Cuota anual federados:</h3>
                        <ul>
                          {sportPopUp.costeFederado?.map((coste, index) => (
                            <li key={index}>{coste}</li>
                          ))}{" "}
                          {/* Generación automática de elementos li */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.card__column2}>
                  <div className={styles.column2__image}>
                    <img src={sportPopUp.popUpImage} />
                  </div>
                  <div className={styles.schedule}>
                    <h2>HORARIOS</h2>
                    <div className={styles.schedule__table}>
                      <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <h3>L</h3>
                            </th>
                            <th>
                              <h3>M</h3>
                            </th>
                            <th>
                              <h3>X</h3>
                            </th>
                            <th>
                              <h3>J</h3>
                            </th>
                            <th>
                              <h3>V</h3>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sportPopUp.sportInfoArray.map((team, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>
                                <h3 className={styles.teamLocation}>
                                  {team.location}
                                </h3>{" "}
                                {/* teamLocation */}
                                <p>{team.hours}</p> {/* teamHours */}
                              </td>
                              {(() => {
                                const cells = [];
                                team.daysVisible.forEach(
                                  (visible, colIndex) => {
                                    cells.push(
                                      <td key={colIndex}>
                                        {visible ? (
                                          <div className={styles.table__cell}>
                                            <CabraLogo
                                              cabraColor={team.cabraColor}
                                              alt={team.teamName}
                                              className={styles.table__image}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            className={
                                              styles.table__placeholder
                                            }
                                          ></div>
                                        )}
                                      </td>
                                    );
                                  }
                                );
                                return cells;
                              })()}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <h2 className={styles.mapsTitle}>DÓNDE ESTAMOS</h2>
                  <AccordionList props={sportPopUp.sportInfoArray} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  /* CLOSE POPUP PRESSING ESC KEY */

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        handleClosePopup();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div>
        <p className={styles.overlay__trigger} onClick={handleOpenPopup}>
          MÁS INFORMACIÓN
        </p>
        <PopUp show={showPopup} onClose={handleClosePopup} />
      </div>
    </>
  );
}

import React, { useState } from "react";
import styles from "./contactForm.module.css";
import IconLabel from "../IconLabel/IconLabel";
import Card from "../Card/Card";
import PointAndText from "../PointAndText/PointAndText";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    sport: "",
    acceptPolicy: false,
    description: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.acceptPolicy) {
      alert("Debes aceptar la política de privacidad.");
      return;
    }

    emailjs
      .send(
        "service_dkvde2c",
        "template_nm9aiok",
        {
          fullName: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.description,
          sport: formData.sport,
        },
        "i5IAd9fOFv_YSwLzT"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  const navigate = useNavigate();
  function onClickPrivacidad() {
    navigate("/politica-de-privacidad");
    window.scrollTo(0, 0);
  }

  return (
    <Card backgroundColor="var(--color-light)">
      <div className={styles.container}>
        <div className={styles["text-section"]}>
          <PointAndText text="Infórmate" pointColor="var(--color-warning)" />
          <h2 className={styles.title}>JUNTOS CRECEMOS</h2>
          <p className={styles.description}>
            Estamos encantados de resolver tus dudas y ayudarte a encontrar la
            actividad perfecta para ti. ¡Te esperamos!
          </p>
          <div className={styles.iconLabel}>
            <IconLabel
              text="info@cierzo.com"
              onClick={() => alert("Button clicked")}
              buttonBackgroundColor="white"
              backgroundColor="var(--color-warning)"
              textColor="var(--primary-color)"
              icon={<img src="/assets/icons/email.svg" alt="Email Icon" />}
            />
          </div>
        </div>

        {/* Right side - Form */}
        <div className={styles["form-section"]}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              className={styles.input}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <div className={styles.row}>
              <div>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Nº de teléfono"
                  className={styles.input}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className={styles.input}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <input
              type="text"
              name="sport"
              placeholder="¿Qué deporte te interesa?"
              className={styles.input}
              value={formData.sport}
              onChange={handleChange}
              required
            />
            <textarea
              name="description"
              placeholder="¿Tienes algo que comentarnos?"
              className={styles.textarea}
              value={formData.description}
              onChange={handleChange}
              rows="4"
              required
            />
            <div className={styles["checkbox-container"]}>
              <input
                type="checkbox"
                name="acceptPolicy"
                className={styles.checkbox}
                checked={formData.acceptPolicy}
                onChange={handleChange}
                required
              />
              <span className={styles.spanSpan}>
                <a className={styles.notLink}>He leído y acepto la </a>
                <a className={styles.link} onClick={() => onClickPrivacidad()}>
                  política de privacidad
                </a>
                .{" "}
              </span>
            </div>
            <button type="submit" className={styles.button}>
              ENVÍA
            </button>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default ContactForm;

import { useEffect, useState } from "react";

export default function Backgrounds({ imageUrl }) {
  let setup = false;
  const [aboutUsHeight, setAboutUsHeight] = useState(0);
  const [joinCierzoHeight, setJoinCierzoHeight] = useState(0);
  const [findYourTeamHeight, setFindYourTeamHeight] = useState(0);
  const [juegosADCierzoHeight, setJuegosADCierzoHeight] = useState(0);

  useEffect(() => {
    if (!setup) {
      setup = true;

      window.addEventListener("resize", calculateBG());
    }
  }, []);

  function calculateBG() {
    const start = vhToPixels(100);
    const offset = window.scrollY;
    let res;

    const aboutUs = document.querySelector("#aboutUs");
    if (aboutUs) {
      const rect = aboutUs.getBoundingClientRect();

      const top = rect.top + offset;
      const height = rect.height;

      if (top < start) {
        res = start - top;
        setAboutUsHeight(height - res * 2);
      } else {
        res = top - start;
        setAboutUsHeight(height + res * 2);
      }
    }

    const joinCierzo = document.querySelector("#joinCierzo");
    if (joinCierzo) {
      const rect = joinCierzo.getBoundingClientRect();

      const height = rect.height;

      setJoinCierzoHeight(height + res * 2);
    }

    const findYourTeam = document.querySelector("#findYourTeam");
    if (findYourTeam) {
      const rect = findYourTeam.getBoundingClientRect();

      const top = rect.top + offset;
      const height = rect.height;

      setFindYourTeamHeight(height - res * 2);
    }

    const juegosADCierzo = document.querySelector("#juegosADCierzo");
    if (juegosADCierzo) {
      const rect = juegosADCierzo.getBoundingClientRect();

      const top = rect.top + offset;
      const height = rect.height;

      setJuegosADCierzoHeight(height + res * 2);
    }
  }

  function vhToPixels(vh) {
    const viewportHeight = window.innerHeight;
    return (vh / 100) * viewportHeight;
  }

  return (
    <div
      style={{
        top: "5vh",
        width: "100%",
        zIndex: "-1",
        position: "absolute",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize:
            "cover" /* Ensures the background covers the entire element */,
          backgroundPosition: "center" /* Centers the image */,
          height: "95vh" /* Full viewport height */,
        }}
      />
      {aboutUsHeight != 0 ? (
        <div
          style={{
            backgroundColor: "#F5F5F5",
            height: aboutUsHeight + "px",
          }}
        />
      ) : (
        ""
      )}
      {joinCierzoHeight != 0 ? (
        <div
          style={{
            backgroundColor: "#7994FF",
            height: joinCierzoHeight + "px",
          }}
        />
      ) : (
        ""
      )}
      {findYourTeamHeight != 0 ? (
        <div
          style={{
            backgroundColor: "#F5F5F5",
            height: findYourTeamHeight + "px",
          }}
        />
      ) : (
        ""
      )}
      <div
        style={{
          backgroundColor: `linear-gradient(rgba(30, 20, 69, 0.6), rgba(30, 20, 69, 0.6))`,
          backgroundImage: `url(/assets/BaskeFieldBG.png)`,
          backgroundSize:
            "cover" /* Ensures the background covers the entire element */,
          backgroundPosition: "center" /* Centers the image */,
          height: juegosADCierzoHeight + "px" /* Full viewport height */,
        }}
      />
    </div>
  );
}

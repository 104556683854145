import styles from "./accordion.module.css";
import CustomMap from "../CustomMap/CustomMap"; // Importa tu componente de mapa

export default function Accordion({ props, isOpen, onToggle }) {
  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={onToggle}>
        <h3 className={styles.title}>{props.teamName}</h3>
        <span>{isOpen ? "-" : "+"}</span>
      </div>
      <div className={`${styles.content} ${isOpen ? styles.show : ""}`}>
        <div className={styles.location__map}>
          <CustomMap mapProps={props} />
        </div>
      </div>
    </div>
  );
}

import styles from "./transparencia.module.css";
import Card from "../Card/Card";

export default function Transparencia() {
  function openPDF(pdfPath) {
    window.open(pdfPath, "_blank");
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.text}>TRANSPARENCIA</h1>
        <Card>
          <div className={styles.content}>
            <div className={styles.general__doc}>
              <h2>DOCUMENTACIÓN GENERAL</h2>
              <div className={styles.downloadGeneral}>
                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/docs/Certificado de identidad deportiva.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>CERTIFICADO DE IDENTIDAD DEPORTIVA</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/docs/Estatutos Cierzo proLGTB+.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>ESTATUTOS CIERZO PROLGTB+</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/docs/Documento de Constitucion Cierzo proLGTB+.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>CONSTITUCIÓN CIERZO PROLGTB+</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/docs/Registro en asociaciones deportivas.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>REGISTRO EN ASOCIACIONES DEPORTIVA</p>
                </div>
              </div>
            </div>

            <div className={styles.doc1920}>
              <h2>DOCUMENTACIÓN 2019-2020</h2>
              <div className={styles.download1920}>
                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/2018-2019/ACTA ASAMBLEA 16-12-19.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>ACTA ASAMBLEA 16.12.2019</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/2019-2020/Memoria 2019-2020.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>MEMORIA 2018-2019</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "assets/transparencia/2019-2020/Cuentas anuales (Pérdidas y Ganancias) Modelo comparativo.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>CUENTAS ANUALES (PÉRDIDAS Y GANANCIAS)</p>
                </div>
              </div>
            </div>

            <div className={styles.doc1819}>
              <h2>DOCUMENTACIÓN 2018-2019</h2>
              <div className={styles.download1819}>
                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "/assets/transparencia/2018-2019/ACTA ASAMBLEA 16-12-19.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>ACTA ASAMBLEA 16.12.2019</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "/assets/transparencia/2018-2019/Memoria 2018-2019.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>MEMORIA 2018-2019</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "/assets/transparencia/2018-2019/Cuentas anuales (Pérdidas y Ganancias) Modelo comparativo.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>CUENTAS ANUALES (PÉRDIDAS Y GANANCIAS)</p>
                </div>
              </div>
            </div>

            <div className={styles.doc1718}>
              <h2>DOCUMENTACIÓN 2017-2018</h2>
              <div className={styles.download1718}>
                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "/assets/transparencia/2017-2018/Memoria 2017-2018 AD Cierzo proLGTB+.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>MEMORIA 2017-2018 AD CIERZO PROLGTB+</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "/assets/transparencia/2017-2018/Contabilidad 2017.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>CONTABILIDAD 2017</p>
                </div>

                <div
                  className={styles.docContainer}
                  onClick={() =>
                    openPDF(
                      "/assets/transparencia/2017-2018/ACTA ASAMBLEA 22-09-18.pdf"
                    )
                  }
                >
                  <div className={styles.docIcon}>
                    <img src="assets/icons/docIcon.svg" alt="docIcon" />
                  </div>
                  <p>ACTA ASAMBLEA 22.09.2018</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";
import JuegosADCierzo from "../components/JuegosADCierzo/JuegosADCierzo";
import AboutUs from "../components/AboutUs/AboutUsPage";
import Logo from "../components/Logo/Logo";

export default function AboutUsPage({ data }) {
  return (
    <main>
      <Backgrounds imageUrl={"/assets/AboutUsBG.png"}></Backgrounds>
      <Logo />
      <Banner data={data.banner} />
      <Header />
      <AboutUs data={data.aboutUsPage} />
      <JuegosADCierzo juegosADCierzo={data.juegosADCierzo} />
      <ContactForm />
      <Footer footer={data.footer} />
    </main>
  );
}

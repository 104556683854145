import Prensa from "../components/Prensa/Prensa";
import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";

export default function PrensaPage({ data }) {
  return (
    <main id={"prensa"}>
      <Backgrounds imageUrl={"/assets/TransparenciaBG.png"}></Backgrounds>
      <Banner data={data.banner} />
      <Header />
      <Prensa />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ContactForm />
      <Footer footer={data.footer} />
    </main>
  );
}

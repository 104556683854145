import Card from "../Card/Card";
import IconLabel from "../IconLabel/IconLabel";
import PointAndText from "../PointAndText/PointAndText";
import styles from "./union.module.css";

export default function Union() {
  return (
    <Card id={"aboutUs"}>
      <div className={styles.aboutUs}>
        <PointAndText text="Quiénes somos" pointColor="var(--color-accent)" />
        <h1 className={styles.title}>TODOS EN EL MISMO EQUIPO</h1>
        <div className={styles.body}>
          La Asociación Deportiva Cierzo proLGTB+ es un Club Deportivo Elemental
          cuyos objetivos son la participación deportiva y conseguir la plena
          integración de los deportistas LGTB en nuestra comunidad.
        </div>
        <IconLabel
          text="INFÓRMATE"
          backgroundColor="white"
          buttonBackgroundColor="var(--color-warning)"
          textColor="white"
          icon={
            <img
              src={`assets/icons/arrow__down__orange.svg`}
              alt={`arrow__down__orange logo`}
            />
          }
        />
        <div className={styles.values}>
          <div className={`${styles.value} ${styles.diversity}`}>
            <h1 className={styles.text}>DIVERSIDAD</h1>
            <div className={styles.circle}>
              <div className={styles.circle__inner} />
            </div>
          </div>
          <div className={`${styles.value} ${styles.equality}`}>
            <h1 className={styles.text}>IGUALDAD</h1>
            <div className={styles.circle}>
              <div className={styles.circle__inner} />
            </div>
          </div>
          <div className={`${styles.value} ${styles.sport}`}>
            <h1 className={styles.text}>DEPORTE</h1>
            <div className={styles.circle}>
              <div className={styles.circle__inner} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

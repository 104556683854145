import { hover } from "@testing-library/user-event/dist/hover";
import IconLabel from "../IconLabel/IconLabel";
import PointAndText from "../PointAndText/PointAndText";
import SportInfoPopUp from "../SportInfoPopUp/SportInfoPopUp";
import styles from "./chooseyourteam.module.css";

/*
Props for the ChooseYourTeam component:

sports (array):
  - text: string, the description text displayed under "Escoge tu grupo"
  - sportsArray: array, each object in the array contains:
      - url: string, image url for the card
      - sport: string, name of the sport
      - groups: string, the number of groups available for the sport

  - sportPopUp (object):
      - title: string, the title displayed in the popup
      - text: string, the main text displayed in the popup (supports line breaks)
      - mail: string, the email address displayed in the IconLabel component
      - popUpImage : string, image displayed on the component
      - mapUrl : string, location of the sport
      - mapProps (object):
          -mapUrl (string): Google Maps link to the sport location
          -mapHeight (string): height of the map
          -mapWidth (string): width of the map
      - sportInfoArray: array of arrays, each subarray represents a team and contains:
          - cabraColor : string, color of the cabra
          - source: string, URL of the team image
          - teamName: string, name of the team
          - teamLocation: string, location where the team trains (displayed in the table)
          - teamHours: string, training hours (displayed in the table)
          - teamWeek: array of booleans, availability on Monday to Friday

      Available colors for the cabraColor; enter the '#...'

      const colors = [
      { name: 'Sky Blue', value: '#a7c5ff' },
      { name: 'Peach', value: '#ff8b74' },
      { name: 'Lavender', value: '#dcb8ff' },
      { name: 'Pink', value: '#f6aee4' },
      { name: 'Sunshine', value: '#fddb77' },
      { name: 'Cherry', value: '#ff5c72' },
      { name: 'Teal', value: '#57d4c1' },
      { name: 'Aqua', value: '#64d0eb' }
*/

export default function ChooseYourTeam({ sports }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header__title}>
            <PointAndText text="Grupos" pointColor="var(--color-secondary)" />
            <h1>ESCOGE TU GRUPO</h1>
          </div>
          <div className={styles.header__content}>
            <div className={styles.content__text}>
              <h3>{sports.text}</h3>
            </div>
            <div className={styles.content__button}>
              <IconLabel
                text="INFÓRMATE"
                backgroundColor="white"
                buttonBackgroundColor="var(--color-warning)"
                textColor="white"
                icon={
                  <img
                    src={`assets/icons/arrow__down__orange.svg`}
                    alt={`arrow__down__orange logo`}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className={styles.grid}>
          {sports.sportsArray.length > 0 ? (
            sports.sportsArray.map((sportItem, index) => (
              <div key={index} className={styles.grid__sport}>
                <div>
                  <div style={{ width: "100%" }}>
                    <img
                      src={sportItem.url}
                      alt={sportItem.sport}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className={styles.card__text}>
                    <h3>{sportItem.sport}</h3>
                    <p>{sportItem.groups}</p>
                  </div>
                </div>
                <div className={styles.popUp}>
                  <SportInfoPopUp sportPopUp={sportItem.sportPopUp} />
                </div>
              </div>
            ))
          ) : (
            <p>No hay grupos disponibles</p>
          )}
        </div>

        <div className={styles.mobile__button}>
          <IconLabel
            text="INFÓRMATE"
            backgroundColor="white"
            buttonBackgroundColor="var(--color-warning)"
            textColor="white"
            icon={
              <img
                src={`assets/icons/arrow__down__orange.svg`}
                alt={`arrow__down__orange logo`}
              />
            }
          />
        </div>
      </div>
    </>
  );
}

import styles from "./politicaprivacidad.module.css";
import Card from "../Card/Card";

export default function PoliticaPrivacidad() {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.text}>POLÍTICA DE PRIVACIDAD</h1>

        <Card>
          <div className={styles.content}>
            <p>
              En A.D. Cierzo proLGTB+ estamos comprometidos con la protección de
              la privacidad de nuestros usuarios y participantes. A
              continuación, detallamos cómo recopilamos, usamos, almacenamos y
              protegemos tus datos personales cuando participas en los Juegos de
              A.D. Cierzo o utilizas nuestros servicios.
            </p>
            <br />

            <h3>1. Responsable del Tratamiento de Datos</h3>

            <p>Asociación Deportiva Cierzo proLGTB+</p>

            <p>
              Correo electrónico:{" "}
              <a href="mailto:juegoscierzo@cierzolgtb.org">
                juegoscierzo@cierzolgtb.org
              </a>
            </p>
            <br />

            {/* (continúa con el resto del contenido) */}

            <h3>9. Contacto</h3>
            <p>
              Si tienes alguna duda o consulta sobre nuestra Política de
              Privacidad o sobre el tratamiento de tus datos personales, puedes
              contactarnos en:{" "}
              <a href="mailto:juegoscierzo@cierzolgtb.org">
                juegoscierzo@cierzolgtb.org
              </a>
            </p>
          </div>
        </Card>
      </div>
    </>
  );
}
